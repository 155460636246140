import React from 'react'
import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'
import tech from '../images/technologies/tech-windowsserverclient-d6653bdec5086a2dff2aaa93a8fdd5ed.png'
import eset from '../images/technologies/eset.png'
import anydesk from '../images/technologies/anydesk.png'
import google from '../images/technologies/tech-googleworkspace-f57cb6efe1e92d4a634c38f81f01a577.png'
import aws from '../images/technologies/tech-aws-43bfc090c06f02711452ed165987524a.png'
import microsoft2 from '../images/icons/tech-azure-45a8823195aba3d07c50151e21327f86.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import godaddy from '../images/icons/tech-godaddy-235e0cdd614e1205e3f461c9ac7414f9.png'
import alexa from '../images/icons/tech-alexa-b3d4c51793c5849253afc256a060469c.png'
import rackspace from '../images/icons/tech-rackspace-c5f31b85f37c8f063afb33082fb5d7a2.png'
import vm from '../images/icons/tech-vmware-6cd92277e83e665df9258ee46c4e8744.png'

import bitdefender from '../images/technologies/bitdefender.png'
import tplink from '../images/technologies/tplink.png'
import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import linux from '../images/technologies/linux.png'

import egn from '../images/technologies/egn.png'
import sav from '../images/technologies/acronis.png'

import python from '../images/technologies/python.png'
import flask from '../images/technologies/flask.png'



const clients = [
    {
        name: 'aws',
        logo: aws,
        url: '#'
    },
    {
        name: 'microsoft2',
        logo: microsoft2,
        url: '#'
    },
    {
        name: 'godaddy',
        logo: godaddy,
        url: '#'
    },
    {
        name: 'alexa',
        logo: alexa,
        url: '#'
    },
    {
        name: 'google',
        logo: google,
        url: '#'
    },
    {
        name: 'rackspace',
        logo: rackspace,
        url: '#'
    },
    {
        name: 'vm',
        logo: vm,
        url: '#'
    },
    {
        name: 'egn',
        logo: egn,
        url: '#'
    },
    {
        name: 'sav',
        logo: sav,
        url: '#'
    },
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: '#'
    },
    {
        name: 'tplink',
        logo: tplink,
        url: '#'
    },
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: '#'
    },
    {
        name: 'paloalto',
        logo: paloalto,
        url: '#'
    },
    {
        name: 'linux',
        logo: linux,
        url: '#'
    },
    {
        name: 'flask',
        logo: flask,
        url: 'https://flask.palletsprojects.com/en/3.0.x/'
    },
    {
        name: 'python',
        logo: python,
        url: 'https://www.python.org/'
    }
]

const HomeClientTestimonials = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Testimonials<span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>CLOUDOPS <span>TECHNOLOGIESaaa</span></h2>
                 
                </div>
            </div>
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className="respimg" alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeClientTestimonials
