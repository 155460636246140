import React from 'react'
import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'
import tech from '../images/technologies/tech-windowsserverclient-d6653bdec5086a2dff2aaa93a8fdd5ed.png'
import eset from '../images/technologies/eset.png'
import anydesk from '../images/technologies/anydesk.png'
import google from '../images/technologies/tech-googleworkspace-f57cb6efe1e92d4a634c38f81f01a577.png'
import aws from '../images/technologies/tech-aws-43bfc090c06f02711452ed165987524a.png'
import microsoft from '../images/technologies/microsoft.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import egn from '../images/technologies/egn.png'
import sav from '../images/technologies/acronis.png'

import tplink from '../images/technologies/tplink.png'
import linux from '../images/technologies/linux.png'
import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'
import quantam from '../images/technologies/quantam.png'
import strata from '../images/technologies/strata.png'
import hyper from '../images/technologies/hyper.png'
import vbox from '../images/technologies/vbox.png'
import ansible from '../images/technologies/ansible.png'










import python from '../images/clients/python.png'






const clients = [
    {
        name: 'synology',
        logo: synology,
        url: 'https://www.synology.com/en-uk'
    },
    {
        name: 'cisco',
        logo: cisco,
        url: 'https://www.cisco.com/'
    },
    {
        name: 'fortinet',
        logo: fortinet,
        url: 'https://www.fortinet.com/'
    },
    {
        name: 'tech',
        logo: tech,
        url: 'https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/remote-desktop-clients'
    },
    {
        name: 'eset',
        logo: eset,
        url: 'https://www.eset.com/lk/'
    },
    {
        name: 'anydesk',
        logo: anydesk,
        url: 'https://anydesk.com/en'
    },
    {
        name: 'google',
        logo: google,
        url: 'https://workspace.google.com/'
    },
    {
        name: 'aws',
        logo: aws,
        url: 'https://aws.amazon.com/'
    },
    {
        name: 'microsoft',
        logo: microsoft,
        url: 'https://www.office.com/'
    },
    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'grafana',
        logo: grafana,
        url: 'https://grafana.com/'
    },
    {
        name: 'egn',
        logo: egn,
        url: 'https://www.egnyte.com/'
    },
    {
        name: 'sav',
        logo: sav,
        url: 'https://www.acronis.com/'
    },
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: 'https://www.bitdefender.com/'
    },
    {
        name: 'tplink',
        logo: tplink,
        url: 'https://www.tp-link.com/'
    },
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: 'https://www.checkpoint.com/'
    },
    {
        name: 'quantam',
        logo: quantam,
        url: '#'
    },
    {
        name: 'paloalto',
        logo: paloalto,
        url: 'https://www.paloaltonetworks.com/'
    },
    {
        name: 'linux',
        logo: linux,
        url: 'https://www.linux.org/'
    },
    {
        name: 'strata',
        logo: strata,
        url: '#'
    },
    {
        name: 'hyper',
        logo: hyper,
        url: '#'
    },
    {
        name: 'vbox',
        logo: vbox,
        url: 'https://www.virtualbox.org/'
    },
    {
        name: 'ansible',
        logo: ansible,
        url: 'https://www.ansible.com/'
    },
    
    {
        name: 'python',
        logo: python,
        url: '#'
    }
]

const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Testimonials<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>TECHNOLOGY <span>CAPABILITIES</span></h2> 
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
